import axios from 'axios';
import Swal from "sweetalert2";
import {
    loginConfirmedAction,
    Logout as logoutAction,
    LOGOUT_ACTION // Tambahkan ini
} from '../store/actions/AuthActions';

import config from '../config';

let refreshInterval = null;

export function signUp(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `${config.baseUrl}/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(username, password) {
    const postData = {
        username,
        password
    };
    return axios.post(`${config.baseUrl}/login`, postData);
}

export function refresh() {
    let req = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}/refresh`,
        headers: { 
          'Authorization': localStorage.getItem('access_token')
        }
      };
      
    return axios.request(req)
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Email already exists',                
            });
            break;
        case 'EMAIL_NOT_FOUND':
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Email not found',                
            });
            break;
        case 'INVALID_PASSWORD':
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Invalid Password',                
            });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + 3600 * 1000, // Umur token = 3600 detik = 1 jam
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('access_token', tokenDetails.access_token);
    localStorage.setItem('userRoles', JSON.stringify(tokenDetails.roles?.split('||')));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);

    // Clear any existing intervals
    if (refreshInterval) {
        clearInterval(refreshInterval);
    }

    // Schedule first token refresh after 5 minutes
    refresh().then((response) => {
        if (response.data.status) {
            const newTokenDetails = {
                ...tokenDetails,
                access_token: response.data.data.access_token,
                expireDate: new Date(new Date().getTime() + 3600 * 1000)
            };
            saveTokenInLocalStorage(newTokenDetails);
            dispatch(loginConfirmedAction(newTokenDetails));
            
            // Schedule subsequent token refreshes every 5 minutes
            refreshInterval = setInterval(() => {
                refresh().then((response) => {
                    if (response.data.status) {
                        const newTokenDetails = {
                            ...tokenDetails,
                            access_token: response.data.data.access_token,
                            expireDate: new Date(new Date().getTime() + 3600 * 1000)
                        };
                        saveTokenInLocalStorage(newTokenDetails);
                        dispatch(loginConfirmedAction(newTokenDetails));
                    } else {
                        dispatch(Logout(navigate));
                    }
                }).catch(() => {
                    dispatch(Logout(navigate));
                });
            }, 5 * 60 * 1000); // 5 minutes in milliseconds
        } else {
            dispatch(Logout(navigate));
        }
    }).catch(() => {
        dispatch(Logout(navigate));
    });
}

export function Logout(navigate) {
    // Clear the interval when logging out
    if (refreshInterval) {
        clearInterval(refreshInterval);
    }

    localStorage.removeItem('userDetails');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userRoles');
    navigate('/login');
    
    return {
        type: LOGOUT_ACTION,
    };
}
